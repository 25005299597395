import React from "react"
import {Divider, Drawer, List, makeStyles, Theme, Toolbar, Typography} from "@material-ui/core"
import SideNavListItem from "./SideNavListItem"
import Routes from "../routes"
import {
  faBroadcastTower,
  faHome,
  faLink,
} from "@fortawesome/free-solid-svg-icons"
import FontAwesomeIcon from "./FontAwesomeIcon"

const useStyles = makeStyles((theme: Theme) => ({
  drawer: {
    width: 260,
    height: "100%",
  },
  drawerPaper: {
    width: 260,
  },
  toolbar: {
    textAlign: "center",
  },
  nested: {
    "& > .MuiListItem-root": {
      paddingLeft: theme.spacing(4),
    },
  },
}))

export default function SideNav(): JSX.Element {
  const classes = useStyles()

  return (
    <Drawer variant={"permanent"} className={classes.drawer} PaperProps={{className: classes.drawerPaper}}>
      <Toolbar className={classes.toolbar}>
        <Typography variant="h6" component={"h1"} noWrap align={"center"}>
          Whitelist
        </Typography>
      </Toolbar>
      <Divider/>
      <List>
        <SideNavListItem label="Home" to={Routes.home} icon={<FontAwesomeIcon icon={faHome}/>}/>
        <SideNavListItem label="Link Accounts" to={Routes.linkAccounts} icon={<FontAwesomeIcon icon={faLink}/>}/>
        <SideNavListItem label="Broadcaster Area" to={Routes.broadcaster}
                         icon={<FontAwesomeIcon icon={faBroadcastTower}/>}/>
      </List>
    </Drawer>
  )
}
