import React from "react"
import {ListItem, ListItemIcon, ListItemText, makeStyles} from "@material-ui/core"
import {Link} from "react-router-dom"

const useStyles = makeStyles(() => ({
  noUserSelect: {
    userSelect: "none",
  },
}))

type Props = {
  label: string
  to?: string|false
  // eslint-disable-next-line
  icon?: any|false
}

export default function SideNavListItem(props: Props): JSX.Element {
  const classes = useStyles()

  if (!props.to) {
    return (
      <ListItem className={classes.noUserSelect}>
        {!props.icon ? null : (
          <ListItemIcon>
            {props.icon}
          </ListItemIcon>
        )}
        <ListItemText primary={props.label}/>
      </ListItem>
    )
  }

  return (
    <ListItem button component={Link} to={props.to}>
      {!props.icon ? null : (
        <ListItemIcon>
          {props.icon}
        </ListItemIcon>
      )}
      <ListItemText primary={props.label}/>
    </ListItem>
  )
}
