import React, {useState} from "react"
import {Box, Button, Link, Typography} from "@material-ui/core"
import Skeleton from "@material-ui/lab/Skeleton"
import TwitchButton from "../hoc/TwitchButton"
import {faTwitch} from "@fortawesome/free-brands-svg-icons"
import {User} from "../models/User"
import FontAwesomeIcon from "./FontAwesomeIcon"

type Props = {
  loggedIn: User | false | null
  stepNext: () => void
}

export function LinkAccountsStepTwitch(props: Props): JSX.Element {
  const logoutUrl = `/api/logout?back=https://${window.location.host}${location.pathname}`
  const loginUrl = `/api/twitch/authorize?scope=user:read:subscriptions&back=https://${window.location.host}${location.pathname}`

  if (props.loggedIn == null) {
    return (
      <Box height={76}>
        <Typography paragraph>
          <Skeleton width={250}/>
        </Typography>
        <Skeleton width={120} height={36}/>
      </Box>
    )
  }

  if (!props.loggedIn) {
    const [clicked, setClicked] = useState(false)

    return (
      <Box height={76}>
        <Typography paragraph>
          You need to login with Twitch to continue.
        </Typography>
        <TwitchButton
          variant="contained"
          startIcon={<FontAwesomeIcon icon={faTwitch}/>}
          href={loginUrl}
          disabled={clicked}
          onClick={() => setClicked(true)}
        >
          Login with Twitch
        </TwitchButton>
      </Box>
    )
  }

  return (
    <Box height={76}>
      <Typography paragraph>
        You are logged in as: <b>{props.loggedIn.displayName}</b>. Not you? <Link href={logoutUrl}>Logout</Link>
      </Typography>
      <Button
        variant="contained"
        color="primary"
        onClick={props.stepNext}
      >
        Next
      </Button>
    </Box>
  )
}
